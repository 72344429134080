import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, isLoggedIn }) => {
  const location = useLocation();
  let navigate = useNavigate();

  if (!isLoggedIn) {
    navigate(`/login?redirectRoute=${location.pathname}${location.search}`);
    return;
  }
  return <div>{children}</div>;
};

export default ProtectedRoute;
