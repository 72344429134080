import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import classes from "./VerifyEmail.module.css";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import { useState } from "react";
import VerifyEmailForm from "components/forms/verify-email/VerifyEmailForm";

const VerifyEmail = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(true);
  console.log("visible state value", visible);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);

    // toggle the visibility of the Modal
    dispatch(modalActions.toggleModal());
    //to set input element value to buyer last bid
  };

  console.log("sign in modal loaded");

  const modalCloseIconStyle = {
    color: "red", // Change the color to red or any other color
    width: "20px",
    height: "20px",
  };

  return (
    <div className="signin-modal">
      <div className="modal-overlay" />
      <Modal
        open={visible}
        // onOk={handleOk}
        onCancel={handleCancel}
        className={classes.signInModal}
        // style={{direction: "ltr"}}
        centered
        footer={null}
        closeIcon={
          <img
            src={`/news-img/icons/close-icon.svg`}
            alt="Close Icon"
            className={classes.modalIcon}
          />
        } // Custom close icon as an image
      >
        <VerifyEmailForm closeModal={() => handleCancel()} />
      </Modal>
    </div>
  );
};

export default VerifyEmail;
