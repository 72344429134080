import { createSlice } from '@reduxjs/toolkit';

import {
  USER_EXPIRATION_TIME,
  USER_FILTER_VALUES,
  USER_LOCAL_STORAGE_KEY,
  expirationTime
} from '../helpers/constants';
import { auth } from 'configs/FirebaseConfig';


const calculateRemainingTime = (expireTime) => {
  const currentTime = new Date().getTime();
  const adjExpireTime = new Date(expireTime).getTime();

  const remainingTime = adjExpireTime - currentTime;

  return remainingTime;
};

let autoLogoutTimer = null;

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isLoggedIn: false,
    isUserLoading: true,
    accessToken: null,
    creatingNewUser: false,
  },
  reducers: {
    login(state, action) {
      console.log("adding user details in login")
      const { user, accessToken } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.user = null;
      state.accessToken = null;
      state.isLoggedIn = false;
      state.creatingNewUser = false;
    },
    setUserLoading(state, action) {
      state.isUserLoading = action.payload;
    },
    updateAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    updateUserName(state, action) {
      state.user = { ...state.user, firstName: action.payload };
    },
    updateItsCreatingUser(state) {
      state.creatingNewUser = true;
    },
    updateNotCreatingUser(state) {
      state.creatingNewUser = false;
    }
  }
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
























// const userSlice = createSlice({
//   name: 'user',
//   initialState: {
//     user: null,
//     isLoggedIn: false,
//     isUserLoading: true,
//   },
//   reducers: {
//     login(state, action) {
//       const { user, remember } = action.payload;

//       const userJson = JSON.stringify(user);
//       // save the user data and expiration time in local storage
//       localStorage.setItem(USER_LOCAL_STORAGE_KEY, userJson);

//       if (!remember) {
//         //if remember me option not enabled add expiry time for local storage
//         localStorage.setItem(USER_EXPIRATION_TIME, expirationTime);
//         const remainingTime = calculateRemainingTime(expirationTime);
//         autoLogoutTimer = setTimeout(
//           userSlice.caseReducers.logout,
//           remainingTime
//         );
//       }

//       // set the state with the new user
//       state.user = user;
//       state.isLoggedIn = true;
//     },
//     logout(state) {
//       // remove any saved users in local storage
//       localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
//       localStorage.removeItem(USER_EXPIRATION_TIME);
//       //to remove location saved by user
//       localStorage.removeItem(USER_FILTER_VALUES);

//       clearTimeout(autoLogoutTimer);
//       // remove user from state
//       state.user = null;
//       state.isLoggedIn = false;
//     },
//     autoLogin(state) {
//       const storedUser = JSON.parse(
//         localStorage.getItem(USER_LOCAL_STORAGE_KEY)
//       );
//       const storedExpirationTime = localStorage.getItem(USER_EXPIRATION_TIME);
//       if (!storedUser) {
//         // userSlice.caseReducers.logout();
//         state.user = null;
//         state.isLoggedIn = false;
//         return;
//       }

//       if (Date.now() >= +storedUser.sessionExpiry) {
//         return;
//       }

//       let remainingTime = null;

//       if (storedExpirationTime) {
//         remainingTime = calculateRemainingTime(storedExpirationTime);
//         if (remainingTime <= 0) {
//           localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
//           localStorage.removeItem(USER_EXPIRATION_TIME);

//           clearTimeout(autoLogoutTimer);

//           state.user = null;
//           state.isLoggedIn = false;
//           return;
//         }
//       }

//       if (remainingTime) {
//         autoLogoutTimer = setTimeout(
//           userSlice.caseReducers.logout,
//           remainingTime
//         );
//       }

//       state.user = storedUser;
//       state.isLoggedIn = true;
//     },
//     updateUser(state, action) {
//       const { user } = action.payload;

//       state.user.user = user;

//       const userJson = JSON.stringify(state.user);
//       // save the user data and expiration time in local storage
//       localStorage.setItem(USER_LOCAL_STORAGE_KEY, userJson);
//     }
//   }
// });

// export const userActions = userSlice.actions;
// export default userSlice.reducer;
