import React, { useState } from "react";
import classes from "./GalleryGridPage.module.css";
import { ConfigProvider, Image } from "antd";
import getDateInFormat from "helpers/getDateInFormat";

const GalleryGridPage = ({ list }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);

  const handleImageClick = (images) => {
    setCurrentImages(images);
    setPreviewVisible(true);
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgMask: "rgba(0, 0, 0, 0.83)", // Change the mask background color here
        },
      }}
    >
      <div className={classes.gridContainer}>
        {list?.map((item, index) => (
          <div
            key={index}
            className={classes.itemContainer}
            onClick={() => handleImageClick(item?.images)}
          >
            <img
              src={item.images[0]?.imageUrl}
              alt={item.alt}
              className={classes.image}
            />
            <div className={classes.darkOverlay} />
            <div className={classes.hoverData}>
              <h2 className={classes.title}>{item.title}</h2>
              <h2 className={classes.date}>
                {getDateInFormat(item.createdAt)}
              </h2>
              <h2 className={classes.author}>{item.userName}</h2>
            </div>
          </div>
        ))}
        {previewVisible && (
          <Image.PreviewGroup
            preview={{
              visible: previewVisible,
              onVisibleChange: (vis) => setPreviewVisible(vis),
              imageRender: (originalNode, info) => {
                console.log("555", originalNode, info);
                const caption = currentImages[info.current]?.caption || "";
                const source = currentImages[info.current]?.source || "";
                console.log("555 caption", info);
                return (
                  <div style={{ position: "relative" }}>
                    {originalNode}
                    <div className={classes.imageCaption}>
                      {caption} &nbsp;&nbsp; {source && `(Source: ${source})`}
                    </div>
                  </div>
                );
              },
            }}
          >
            {currentImages.map((img, idx) => (
              <>
                <Image key={idx} src={img.imageUrl} />
              </>
            ))}
          </Image.PreviewGroup>
        )}
      </div>
    </ConfigProvider>

    // <div className={classes.gridContainer}>
    //   {list?.map((item) => (
    //     <div className={classes.itemContainer}>
    //       <Image.PreviewGroup
    //         preview={{
    //           visible: false, // Prevent opening all images at once unless clicked
    //         }}
    //         items={item.images}
    //       >
    //         <Image
    //           className={classes.image}
    //           src={item.image}
    //           onClick={() => {
    //             // Open preview when clicking the first image
    //             Image.PreviewGroup.open({
    //               items: item?.images?.map((img) => ({
    //                 src: img,
    //               })),
    //             });
    //           }}
    //         />
    //       </Image.PreviewGroup>
    //       <div className={classes.darkOverlay} />
    //       <div className={classes.hoverData}>
    //         <h2 className={classes.title}>{item.caption}</h2>
    //         <h2 className={classes.date}>JULY 17, 2017 AT 12:48 PM</h2>
    //         <VerifiedContent />
    //       </div>
    //     </div>
    //   ))}
    // </div>

    // <div className={classes.gridContainer}>
    //   {list?.map((item) => (
    //     <div className={classes.itemContainer}>
    //       <img src={item.image} alt={item.alt} className={classes.image} />
    //       <div className={classes.darkOverlay} />
    //       <div className={classes.hoverData}>
    //         <h2 className={classes.title}>{item.caption}</h2>
    //         <h2 className={classes.date}>JULY 17, 2017 AT 12:48 PM</h2>
    //         <VerifiedContent />
    //       </div>
    //     </div>
    //   ))}
    // </div>
  );
};

export default GalleryGridPage;
