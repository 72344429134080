import { NavLink, Link } from "react-router-dom";

import classes from "./NavbarItem.module.css";
import { Dropdown, Menu } from "antd";

const NavbarItem = ({
  children,
  pageLink,
  onClick,
  navItem,
  isMenu,
  menuList,
}) => {
  const menu = { items: menuList };
  return (
    <li className={classes.navbarItem}>
      {navItem && !isMenu && (
        <NavLink
          onClick={onClick}
          to={pageLink}
          end
          className={({ isActive }) => (isActive ? classes.active : undefined)}
        >
          {children}
        </NavLink>
      )}
      {navItem && isMenu && menuList && (
        <Dropdown
          menu={menu}
          placement="bottom"
          trigger={["hover"]}
          overlayClassName="navbar-menu"
          dropdownRender={(menu) => (
            <div className={classes.dropdownMenu}>{menu}</div>
          )}
        >
          <a className={classes.dropDownitems} onClick={(e) => e.preventDefault()}>{children}</a>
        </Dropdown>
      )}
      {!navItem && (
        <Link onClick={onClick} to={pageLink}>
          {children}
        </Link>
      )}
    </li>
  );
};

export default NavbarItem;
