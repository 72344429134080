import Navbar from "./navbar/Navbar";
import classes from "./Layout.module.css";
import { createContext, useEffect, useRef, useState } from "react";
import Footer from "./footer/Footer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Create context outside of the component
export const ModalContext = createContext();

const Layout = ({ children, notScrollable, isAuthLoading }) => {
  const rootRef = useRef(document.getElementById("root"));
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);

  // Add the noScroll class on the root div when displaying a modal
  useEffect(() => {
    if (notScrollable) {
      rootRef.current.classList.add(classes.noScroll);
    } else if (rootRef.current.classList.contains(classes.noScroll)) {
      rootRef.current.classList.remove(classes.noScroll);
    }
  }, [notScrollable]);

  const location = useLocation();

  const loginOrSignupOrForgotPassword =
    location.pathname.includes("login") ||
    location.pathname.includes("signup") ||
    location.pathname.includes("forgot-password");

  const badgeElement = document.querySelector(".grecaptcha-badge");

  useEffect(() => {
    //change the visibility of badge for required urls
    if (badgeElement) {
      badgeElement.style.visibility = loginOrSignupOrForgotPassword
        ? "visible"
        : "hidden";
    }
  }, [location, badgeElement]);

  const { language, direction } = useSelector((state) => state.language);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log("modal open and", isModalOpen);

  return (
    <div dir={direction}>
      <>
        <nav className={classes.navbar}>
          <Navbar isAuthLoading={isAuthLoading} />
        </nav>
        {/* {modalIsVisible && ( */}
        {isModalOpen && <div className="overlay-div"></div>}
        <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
          <main className={classes.mainContent}>{children}</main>
        </ModalContext.Provider>
        <footer className={classes.footer}>
          <Footer language={language} direction={direction} />
        </footer>
      </>
    </div>
  );
};

export default Layout;
