import { useEffect, useRef, useState } from "react";

import { emailIsValid } from "validators/authValidation";

import Button from "components/UI/button/Button";
import MailIcon from "components/UI/icons/MailIcon";
import InputWithLabel from "components/UI/forms/input/InputWithLabel";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";

import classes from "./SignupForm.module.css";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import { useDispatch, useSelector } from "react-redux";

import { useMutation } from "@tanstack/react-query";

import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import {
  API_BASE_URL,
  APP_PREFIX_PATH,
  REACT_APP_DEV_MODE,
} from "configs/AppConfig";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
  message,
  notification,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "configs/FirebaseConfig";
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import getErrorMessage from "helpers/getErrorMessage";
import { InfoCircleOutlined } from "@ant-design/icons";

const rules = {
  required: [
    {
      required: true,
      message: "Required",
    },
  ],
  email: [
    {
      required: true,
      message: "Required",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input your password",
    },
    {
      min: 16,
      message: "Password must have at least 16 characters",
    },
    // {
    //   pattern: new RegExp(
    //     "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*])(?=.{8,})"
    //   ),
    //   message:
    //     "Password must have atleast 8 characters with one lowercase,one uppercase,one number,one special character",
    // },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const email = getFieldValue("email");
        if (value && email && value === email) {
          return Promise.reject(
            new Error("Password cannot be the same as the email address.")
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  confirm: [
    {
      required: true,
      message: "Please confirm your password!",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords do not match!");
      },
    }),
  ],
};

const SignupForm = ({
  isPage = false,
  redirect = false,
  redirectRoute = "",
}) => {
  const [userCreating, setUserCreating] = useState(false);
  const [form] = Form.useForm();
  const { constants } = useSelector((state) => state.constants);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const { Option } = Select;
  const { language, direction } = useSelector((state) => state.language);
  const { t } = useTranslation();

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [recaptchaVerifySuccess, setRecaptchaVerifySuccess] = useState(null);
  const [recaptchaCheckLoading, setRecaptchaCheckLoading] = useState(false);

  // Function to load reCAPTCHA script dynamically
  const loadRecaptchaScript = () => {
    return new Promise((resolve, reject) => {
      if (
        document.querySelector(
          `script[src="https://www.google.com/recaptcha/api.js?render=${
            REACT_APP_DEV_MODE == "Dev"
              ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
              : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
          }"]`
        )
      ) {
        resolve(); // Script is already loaded
      } else {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${
          REACT_APP_DEV_MODE == "Dev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
            : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
        }`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });
  };

  const recaptchaVerification = async () => {
    if (window.grecaptcha) {
      try {
        // Generate the reCAPTCHA v3 token
        const token = await window.grecaptcha.execute(
          REACT_APP_DEV_MODE == "Dev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
            : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE,
          { action: "signin" }
        );
        setRecaptchaToken(token);
        setRecaptchaCheckLoading(true);

        // Verify the token with your backend (if applicable)
        const tokenResponse = await fetch(`${API_BASE_URL}recaptcha/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ token }),
        });

        const tokenResult = await tokenResponse.json();

        if (tokenResult.success) {
          setRecaptchaCheckLoading(false);
          setRecaptchaVerifySuccess(true);
        } else {
          setRecaptchaCheckLoading(false);
          setRecaptchaVerifySuccess(false);
        }
      } catch (error) {
        setRecaptchaCheckLoading(false);
        setRecaptchaVerifySuccess(false);
        console.error("Error during sign-in:", error);
      }
    } else {
      notification.error({
        message: "Error",
        description: "reCAPTCHA is not loaded. Please try again later.",
      });
      await loadRecaptchaScript();
    }
  };

  useEffect(() => {
    recaptchaVerification();
  }, []);

  const shiftToSignIn = () => {
    if (location.pathname.includes("signup")) {
      navigate(`/login?redirectRoute=${redirectRoute}`);
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }
  };

  const submitCreateUser = async () => {
    // e.preventDefault();

    if (recaptchaVerifySuccess == false) {
      notification.error({
        message: "Error",
        description: "reCAPTCHA check failed.",
      });
      return;
    }

    form
      .validateFields()
      .then(async (values) => {
        console.log("the values are", values);
        setUserCreating(true);
        //creating user in firebase
        let userCredential = {};
        dispatch(userActions.updateItsCreatingUser());
        try {
          userCredential = await createUserWithEmailAndPassword(
            auth,
            values.email,
            values.password
          );
        } catch (error) {
          setUserCreating(false);
          dispatch(userActions.updateNotCreatingUser());
          console.log("firebase error", error);
          notification.error({
            message: "Error",
            description: getErrorMessage(error.code),
          });
          return; //to avoid propogation to catch fn below
          // throw new Error(error);
        }
        const user = userCredential.user;
        console.log("user data from firebase", user);
        notification.success({
          message: t("success"),
          description: "Creating User...",
          // style: { direction },
        });

        //creating user file in server
        const sendingValues = {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          customerType: values.customerType,
          phoneNumber: `+${values.phoneNumber}`,
          userType: "Customer",
          loginType: "email",
          // status: "Active",
        };
        console.log("sendingVAlues", sendingValues);

        const response = await fetch(
          `${API_BASE_URL}users/register-firebase-user`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.accessToken}`,
            },
            body: JSON.stringify(sendingValues),
          }
        );

        const data = await response.json();
        console.log("data from server api", data);

        if (!response.ok) {
          console.log("Error", data);
          await signOut(auth); //to avoid logged in condition from firebase eventhough user create api failed
          setUserCreating(false);
          if (Array.isArray(data.message)) {
            data.message.forEach((error) => {
              notification.error({
                message: t("error"),
                description: error,
              });
            });
          } else {
            console.log("entered here 222");
            notification.error({
              message: t("error"),
              description: data.message ? data.message : "An error occured",
            });
          }
          return;
          // throw new Error(data.message);
        }
        if (data.success) {
          form.resetFields();
          //refresh token after new user registered to get all data in token
          const idTokenResult = await auth.currentUser.getIdToken(true);
          console.log("id token 333", idTokenResult);
          dispatch(userActions.updateAccessToken(idTokenResult));
          notification.success({
            message: t("success"),
            description: "User created successfully",
            style: { direction },
          });
          await signOut(auth);
          shiftToSignIn();
        }
        setUserCreating(false);
      })
      .catch((info) => {
        console.log("error in form", info);
        setUserCreating(false);
        message.error("Please check all input fields");
      });
  };

  function formatString(str) {
    // Capitalize the first letter
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    // Add a space before capital letters (for camelCase strings)
    const spaced = capitalized.replace(/([a-z])([A-Z])/g, "$1 $2");

    return spaced;
  }

  return (
    // <form
    //   dir={direction}
    //   className={classes.signUpForm}
    //   onSubmit={onSumbitFormHandler}
    // >
    <Form
      layout="vertical"
      form={form}
      dir={direction}
      name="filter_form"
      className={`ant-advanced-search-form w-100 connectForm custom-register-form-class ${classes.signUpForm}`}
    >
      <div className={classes.profileContainer}>
        <img
          className={classes.profileIcon}
          src="/news-img/icons/profile-icon.svg"
        />
      </div>
      <h1>{t("register_here")}</h1>
      <div className={classes.inputField}>
        <span className={classes.inputLabel}>{t("registering_as")}</span>
        <Form.Item name="customerType" rules={rules.required}>
          <Select
            // className="register-select"
            // defaultValue="option1"
            // showSearch
            // filterOption={(input, option) =>
            //   option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            // optionFilterProp="label"
            // onChange={handleChange}
            placeholder={t("individual_or_organization")}
            suffixIcon={
              <img
                src="/news-img/icons/antd-arrow.svg"
                alt="custom arrow"
                className={classes.customArrow}
              />
            }
          >
            {constants?.Customertype?.map((type) => (
              <Option value={type}>{formatString(type)}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className={classes.inputField}>
        <span className={classes.inputLabel}>{t("first_name")}</span>
        <Form.Item name="firstName" rules={rules.required}>
          <Input
            placeholder={t("first_name")}
            // className="signInInput"
            // value={name}
            // onChange={(e) => setName(e.target.value)}
            // onBlur={checkNameValid}
          />
        </Form.Item>
      </div>
      <div className={classes.inputField}>
        <span className={classes.inputLabelOptional}>{t("middle_name")}</span>
        <Form.Item name="middleName">
          <Input
            placeholder={t("middle_name")}
            // className="signInInput"
            // value={name}
            // onChange={(e) => setMiddleName(e.target.value)}
            // onBlur={checkNameValid}
          />
        </Form.Item>
      </div>
      <div className={classes.inputField}>
        <span className={classes.inputLabel}>{t("last_name")}</span>
        <Form.Item name="lastName" rules={rules.required}>
          <Input
            placeholder={t("last_name")}
            // className="signInInput"
            // value={name}
            // onChange={(e) => setLastName(e.target.value)}
            // onBlur={checkNameValid}
          />
        </Form.Item>
      </div>
      <div className={classes.inputField}>
        <span className={classes.inputLabel}>{t("email")}</span>
        <Form.Item name="email" rules={rules.email}>
          <Input
            placeholder={t("email")}
            // className="signInInput"
            // value={email}
            // onChange={(e) => setEmail(e.target.value)}
            // onBlur={checkEmailValid}
          />
        </Form.Item>
        {/* <p className={classes.inputBottom}>{t("confirmation_email")}</p> */}
      </div>
      <div className={classes.inputField}>
        <span className={classes.inputLabelOptional}>{t("phone_number")}</span>
        <Form.Item
          name="phoneNumber"
          // rules={rules.required}
          className="register-phone-input"
        >
          <PhoneInput
            // inputProps={{
            //   name: "mobileNumber",
            // }}
            dir={direction}
            country={"in"}
            // value={mobileNumber}
            // onChange={(value) => setMobileNumber(`+${value}`)}
            // parentClass={"register-phone-input"}
            // dropdownStyle={{ width: "50px" }}
            enableSearch={true}
            specialLabel=""
            disableDropdown={false}
            countryCodeEditable={true}
            // placeholder={t("enter_phone")}
          />
        </Form.Item>
      </div>

      <div className={classes.inputField}>
        {/* <span className={classes.inputLabel}>{t("password")}</span> */}
        <Tooltip placement="top" title={t("enter_password_with_16_characters")}>
          <span className={`${classes.inputLabel} fitContent`}>
            {t("password")} <InfoCircleOutlined />
          </span>
        </Tooltip>
        <Form.Item name="password" rules={rules.password}>
          <Input.Password
            placeholder={t("type_your_password")}
            // className="signInInput2"
            // onChange={(e) => setPassword(e.target.value)}
            // onBlur={checkPasswordValid}
          />
        </Form.Item>
        {/* <p className={classes.inputBottom}>{t("password_rule")}</p> */}
      </div>
      <div className={classes.passwordField}>
        <span className={classes.inputLabel}> {t("reenter_password")} </span>

        <Form.Item name="confirm" rules={rules.confirm}>
          <Input.Password
            placeholder={t("reenter_password")}
            // className="signInInput2"
            // onChange={handleConfirmPasswordChange}
            // onBlur={checkPasswordValid}
          />
        </Form.Item>
      </div>
      <div className={classes.recaptchaContent}>
        {recaptchaCheckLoading && (
          <div className={classes.checking}>
            <LoadingOutlined
              style={{ width: "14px", color: "blue", fontWeight: "700" }}
            />
            Verifying reCAPTCHA...
          </div>
        )}
        {!recaptchaCheckLoading && recaptchaVerifySuccess && (
          <div className={classes.checkSuccess}>
            <CheckCircleOutlined style={{ width: "16px", fontWeight: "700" }} />
            reCAPTCHA verification successful!
          </div>
        )}
        {!recaptchaCheckLoading && recaptchaVerifySuccess == false && (
          <div className={classes.checkFailed}>
            <CloseCircleOutlined
              style={{ width: "16px", marginRight: "5px", color: "red" }}
            />
            reCAPTCHA verification failed!
            <span
              className={classes.tryAgain}
              onClick={() => recaptchaVerification()}
            >
              Try Again
            </span>
          </div>
        )}
      </div>
      <div className={classes.buttonContainer}>
        <button
          className={classes.signUpButton}
          onClick={() => submitCreateUser()}
        >
          {userCreating && (
            <LoadingOutlined style={{ width: "15px", marginRight: "5px" }} />
          )}
          {t("continue")}
        </button>
      </div>
      <section className={classes.signUpFooter}>
        <p>
          {t("already_user")} ?
          <span className={classes.signUpLink} onClick={shiftToSignIn}>
            &nbsp;{t("login")}
          </span>
        </p>
      </section>
      {/* </form> */}
    </Form>
  );
};

export default SignupForm;
